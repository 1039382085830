import { Card, Select } from "@mui/material";
import React, { useContext } from "react";
import { LanguageContext } from "../context/LanguageContext";

export default function LanguageSelect(){

    const locale=useContext(LanguageContext);

    return(
        <Card
            sx={{
                position:'absolute',
                bottom:'1rem',
                right:'1rem',
                filter:'drop-shadow(0 0 0.5rem #fff)'
            }}
        >
            <Select
                native
                value={locale.lang.code}
                onChange={e=>locale.updateLang(e.target.selectedIndex)}
                sx={{
                    '& select':{
                        padding:'0.5rem 2rem 0.5rem 0.5rem',
                        background:'#000',
                        color:'#fff'
                    },
                    '& svg':{
                        color:'#fff'
                    }
                }}
            >
                {locale.langs.map((lang)=>{
                    return(
                        <option value={lang.code} key={lang.code}>
                            {lang.name}
                        </option>
                    );
                })}
            </Select>
        </Card>
    );
}